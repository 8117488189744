<template>
    <div class="box">
        <div class="null-page" v-show="false"></div>
        <div class="member-info" v-loading="loading">
            <h3>我的发票</h3>
            <el-divider></el-divider>
            <el-card class="box-card">
                <div class="card-title">
                    <h3 style="width:360px;">增值税专用发票信息</h3>
                    <el-button type="success" style="float:right" @click="showDialogAddZhan" plain v-if="!formDataIsSetShow">添加</el-button>
                </div>
                <el-form :model="formData" label-width="160px" v-if="formDataIsSetShow">
                    <el-divider></el-divider>
                    <el-form-item label="单位名称(发票抬头)">
                        <el-input v-model="formData.company_name" :disabled="!formIsEdit" style="width: 360px" placeholder="单位名称(发票抬头)" class="ns-len-input"></el-input>
                    </el-form-item>
                    <el-form-item label="纳税人识别号" prop="company_no">
                        <el-input v-model="formData.company_no" :disabled="!formIsEdit" placeholder="纳税人识别号" style="width: 360px" maxlength="50" show-word-limit class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item label="单位地址" prop="full_address">
                        <el-select :disabled="!formIsEdit" v-model="formData.province_id" placeholder="请选择省" @change="changeProvice(formData.province_id)" style="margin-right:10px;">
                            <el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <el-select :disabled="!formIsEdit" v-model="formData.city_id" placeholder="请选择市" @change="changeCity(formData.city_id)" style="margin-right:10px;">
                            <el-option v-for="option in city" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                        <el-select :disabled="!formIsEdit" v-model="formData.district_id" placeholder="请选择区/县" @change="changeDistrict(formData.district_id)" style="margin-right:10px;">
                            <el-option v-for="option in district" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="单位详细地址" prop="address">
                        <el-input :disabled="!formIsEdit" v-model.trim="formData.address" autocomplete="off" maxlength="50" show-word-limit placeholder="单位详细地址" class="ns-len-input"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" prop="company_tel">
                        <el-input :disabled="!formIsEdit" v-model="formData.company_tel" style="width: 360px" autocomplete="off" placeholder="电话" class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item label="开户银行支行" prop="bank_name">
                        <el-input :disabled="!formIsEdit" v-model.trim="formData.bank_name" style="width: 360px" autocomplete="off" placeholder="开户银行支行" class="ns-len-input"></el-input>
                    </el-form-item>

                    <el-form-item label="银行账户" prop="bank_account">
                        <el-input :disabled="!formIsEdit" v-model.trim="formData.bank_account" style="width: 360px" autocomplete="off" placeholder="银行账户" class="ns-len-input"></el-input>
                    </el-form-item>
                    <el-input v-model.trim="formData.full_address" type="hidden" show-word-limit placeholder="单位完整地址" class="ns-len-input"></el-input>
                    <el-form-item>
                    <el-button type="success" plain v-if="formDataIsSetShow && formDataIsSet && !formIsEdit" @click="formIsEditChange()">编辑</el-button>
                        <el-button style="margin-left:20px;" v-if="formIsEdit" plain @click="saveFormInvoice()">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-card class="box-card" style="margin-top:10px;">
                <div class="card-title">
                    <h3 style="width:360px;">增值税普通发票信息</h3>
                    <el-button type="success" style="float:right" @click="showDialogAdd1" plain>添加</el-button>
                </div>
                <el-divider></el-divider>
                <div v-loading="loading" class="withdrawal-list">
                    <el-table v-if="formDataList.length > 0" :data="formDataList">
                        <el-table-column label="序号" type="index" width="50" align="center">
                            <template scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="company_name" label="发票抬头" width="390px"></el-table-column>
                        <el-table-column prop="company_no" label="纳税人识别号" width="260px"></el-table-column>
                        <el-table-column property="menusstate" label="是否默认">
                            <template scope="scope">
                                <el-switch on-text="是" off-text="否" on-color="#5B7BFA" off-color="#dadde5" v-model="scope.row.is_default_b" @change="changeSwitch(scope.$index, scope.row)"> </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="220px">
                            <template slot-scope="scope">
                                <el-button size="mini" type="success" @click="editInvoice(scope)" plain>修改</el-button>
                                <el-button size="mini" type="danger" @click="deleteInvoice(scope)" plain>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-else-if="!loading && formDataList.length == 0" class="ns-text-align">暂无记录</div>
                </div>
            </el-card>
            <el-card class="box-card" style="margin-top:10px;">
                <div class="card-title">
                    <h3 style="width:360px;">个人普通发票信息</h3>
                    <el-button type="success" style="float:right" @click="showDialogAdd3" plain>添加</el-button>
                </div>
                <el-divider></el-divider>
                <div v-loading="loading" class="withdrawal-list">
                    <div v-if="formDataList2.length > 0">
                        <el-table :data="formDataList2">
                            <el-table-column label="序号" type="index" width="50" align="center">
                                <template scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="company_name" label="发票抬头" width="650px"></el-table-column>
                            <el-table-column property="menusstate" label="是否默认">
                                <template scope="scope">
                                    <el-switch on-text="是" off-text="否" on-color="#5B7BFA" off-color="#dadde5" v-model="scope.row.is_default_b" @change="changeSwitch(scope.$index, scope.row)"> </el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="220px">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="success" @click="editInvoice(scope)" plain>修改</el-button>
                                    <el-button size="mini" type="danger" @click="deleteInvoice(scope)" plain>删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-else-if="!loading && formDataList2.length == 0" class="ns-text-align">暂无记录</div>
                </div>
            </el-card>
        </div>
        <el-dialog title="增值税普通发票信息" :visible.sync="formDataDialogVisible"  @close='closeDialog'>
            <el-form ref="ruleForm" :model="formDataDialog" label-width="160px">
                <el-form-item label="发票抬头">
                    <el-input v-model="formDataDialog.company_name" :rules="[{ required: true, message: '不能为空' }]" style="width: 360px" placeholder="发票抬头" class="ns-len-input"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别号" prop="company_no">
                    <el-input :rules="[{ required: true, message: '不能为空' }]" v-model="formDataDialog.company_no" placeholder="单位信用码" style="width: 360px" maxlength="50" show-word-limit class="ns-len-input"></el-input>
                </el-form-item>
                <el-input v-model="formDataDialog.invoice_type" style="width: 100px" type="hidden"></el-input>
                <el-input v-model="formDataDialog.invoice_id" style="width: 100px" type="hidden"></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="formDataDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveInvoice(1)">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="个人普通发票信息" :visible.sync="formDataDialogVisible2"  @close='closeDialog'>
            <el-form ref="ruleForm2" :model="formDataDialog2" label-width="160px">
                <el-form-item label="发票抬头">
                    <el-input v-model="formDataDialog2.company_name" :rules="[{ required: true, message: '不能为空' }]" style="width: 360px" placeholder="发票抬头" class="ns-len-input"></el-input>
                </el-form-item>
                <el-input v-model="formDataDialog2.invoice_type" style="width: 100px" type="hidden"></el-input>
                <el-input v-model="formDataDialog2.invoice_id" style="width: 100px" type="hidden"></el-input>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="formDataDialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="saveInvoice(3)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { VoucherInvoicelists, VoucherInvoiceapply } from "@/api/member/member"
    import { addressInfo, listsInvoice, createInvoice, updateInvoice, deleteInvoice, setDefaultInvoice } from "@/api/member/member"
    import { getArea } from "@/api/address"

    export default {
        name: "invoice",
        data() {
            return {
                formIsEdit: false,
                formDataDialogVisible: false,
                formDataDialog: {
                    company_name: "", // 单位名称(发票抬头)
                    company_no: "", // 纳税人识别号
                    is_default_b: false, // 形状属性
                    is_default: 0 // 默认开票项
                },
                formSwitch_is_default: false,
                formSwitch_is_default2: false,
                formDataDialogVisible2: false,
                formDataDialog2: {
                    company_name: "", // 单位名称(发票抬头)
                    is_default_b: false, // 形状属性
                    is_default: 0 // 默认开票项
                },
                formDataList: [],
                formDataList2: [],
                dataList: [
                    {
                        company_name: "aaa",
                        company_no: "aaa"
                    }
                ],
                loading: false,
                // 专票是否已经有数据
                formDataIsSet: false,
                // 专票是否显示
                formDataIsSetShow: false,
                formData: {
                    invoice_id: 0,
                    invoice_type: 2,
                    company_name: "", // 单位名称(发票抬头)
                    company_no: "", // 纳税人识别号
                    province_id: "", // 省
                    city_id: "", // 市县
                    district_id: "", //区
                    address: "", // 单位地址
                    full_address: "", // 单位完整地址
                    company_tel: "", // 电话
                    bank_name: "", // 开户银行支行
                    bank_account: "" // 银行账户
                },
                province: [],
                city: [],
                district: [],
                pickerValueArray: [],
                multiIndex: [0, 0, 0],
                isInitMultiArray: false,
                addressValue: "",
                flag: false, //防重复标识
                defaultRegions: [],
                // 是否加载完默认地区
                isLoadDefaultAreas: false,
                // 当前编辑发票类型
                on_invoice_type: 0,
                on_invoice_model: "create"
            }
        },
        created() {
            this.dataInitInvoice()
            this.getDefaultAreas(0, {
                level: 0
            })
        },
        watch: {
            defaultRegions: {
                handler(arr, oldArr = []) {
                    // 避免传的是字面量的时候重复触发
                    if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
                    this.handleDefaultRegions()
                },
                immediate: true
            }
        },
        computed: {
            pickedArr() {
                // 进行初始化
                if (this.isInitMultiArray) {
                    return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
                }
                return [this.pickerValueArray[0], this.city, this.district]
            }
        },
        methods: {
            /** 弹窗关闭事件 */
            closeDialog(){
                this.deleteFormData()
            },
            /** 添加专票 */
            showDialogAddZhan(){
                this.formDataIsSetShow = this.formDataIsSetShow ? false : true
                this.formIsEdit = this.formIsEdit ? false : true
            },
            /** 切换编辑状态 */
            formIsEditChange() {
                this.formIsEdit = this.formIsEdit ? false : true
            },
            /**开关切换 */
            changeSwitch(index, row) {
                let is_default = row.is_default_b ? 1 : 0
                let invoice_id = row.invoice_id
                setDefaultInvoice({
                    invoice_id: invoice_id,
                    is_default: is_default
                })
                    .then(res => {
                        if (res.code == 0) {
                            // 发票信息列表
                            this.dataInitInvoice()
                            this.$message.success("操作成功!")
                        }
                    })
                    .catch(err => {
                        this.flag = false
                        this.$message.error(err.message)
                    })
            },
            // 保存专票
            saveFormInvoice() {
                if (this.formData.company_name.length == 0) {
                    this.$message.error("单位名称(发票抬头)不能为空")
                    return
                }
                if (this.formData.company_no.length == 0) {
                    this.$message.error("纳税人识别号不能为空")
                    return
                }
                if (this.formData.address.length == 0) {
                    this.$message.error("单位地址不能为空")
                    return
                }
                if (this.formData.company_tel.length == 0) {
                    this.$message.error("电话不能为空")
                    return
                }
                if (this.formData.bank_name.length == 0) {
                    this.$message.error("开户银行支行不能为空")
                    return
                }
                if (this.formData.bank_account.length == 0) {
                    this.$message.error("银行账户不能为空")
                    return
                }
                this.on_invoice_type = 2
                // 保存。
                this.saveInvoice()
            },
            // 编辑
            editInvoice(item) {
                let onInvoice = item.row
                this.on_invoice_type = item.row.invoice_type
                this.on_invoice_model = "update"
                if (this.on_invoice_type == 1) {
                    this.formDataDialog.invoice_id = onInvoice.invoice_id
                    this.formDataDialog.company_name = onInvoice.company_name
                    this.formDataDialog.company_no = onInvoice.company_no
                    this.formSwitch_is_default = onInvoice.is_default
                    this.formDataDialogVisible = true
                } else if (this.on_invoice_type == 3) {
                    this.formDataDialog2.invoice_id = onInvoice.invoice_id
                    this.formDataDialog2.company_name = onInvoice.company_name
                    this.formSwitch_is_default2 = onInvoice.is_default
                    this.formDataDialogVisible2 = true
                }
            },
            // 删除
            deleteInvoice(item) {
                this.$confirm("您确定要删除该发票信息吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.deleteInvoiceFun(item.row.invoice_id)
                });
            },
            // 增值税票普票
            showDialogAdd1() {
                this.on_invoice_type = 1
                this.formDataDialogVisible = true
                this.on_invoice_model = "create"
            },
            // 添加个人发票信息
            showDialogAdd3() {
                this.on_invoice_type = 3
                this.formDataDialogVisible2 = true
                this.on_invoice_model = "create"
            },
            /**删除操作 */
            deleteInvoiceFun(invoice_id) {
                deleteInvoice({
                    invoice_id: invoice_id
                })
                    .then(res => {
                        if (res.code == 0) {
                            // 发票信息列表
                            this.dataInitInvoice()
                            this.$message.success("删除成功!")
                        }
                    })
                    .catch(err => {
                        this.flag = false
                        this.$message.error(err.message)
                    })
            },
            // 发票信息列表
            dataInitInvoice() {
                listsInvoice()
                    .then(res => {
                        if (res.code == 0) {
                            this.formDataList = []
                            this.formDataList2 = []
                            res.data.forEach((item, index) => {
                                // 添加字段
                                if (item.is_default == 1) {
                                    item["is_default_b"] = true
                                } else {
                                    item["is_default_b"] = false
                                }
                                if (item.invoice_type == 3) {
                                    this.formDataList2.push(item)
                                }
                                if (item.invoice_type == 1) {
                                    this.formDataList.push(item)
                                }
                                if (item.invoice_type == 2) {
                                    // 专票是否已经有数据
                                    this.formDataIsSet = true
                                    this.formDataIsSetShow = true
                                    this.formData = item
                                    this.defaultRegions = [item.province_id, item.city_id, item.district_id]
                                }
                            })
                        }
                    })
                    .catch(err => {
                        this.flag = false
                        this.$message.error(err.message)
                    })
            },
            /**
             * 保存发票信息，新建或更新
             */
            saveInvoice() {
                let on_invoice_type = this.on_invoice_type
                let on_invoice_model = this.on_invoice_model
                var data = {}

                /**
                 * 发票类型：
                 * 1     //增值税票普票
                 * 2     //增值税票专票
                 * 3     //个人普通发票
                 */
                switch (on_invoice_type) {
                    case 1:
                        if (this.formDataDialog.company_name.length == 0) {
                            this.$message.error("发票抬头不能为空")
                            return
                        }
                        if (this.formDataDialog.company_no.length == 0) {
                            this.$message.error("纳税人识别号不能为空")
                            return
                        }
                        if (this.formSwitch_is_default) {
                            this.formDataDialog.is_default = 1
                        } else {
                            this.formDataDialog.is_default = 0
                        }
                        data = {
                            invoice_type: this.on_invoice_type,
                            invoice_id: this.formDataDialog.invoice_id,
                            company_name: this.formDataDialog.company_name,
                            company_no: this.formDataDialog.company_no,
                            is_default: this.formDataDialog.is_default
                        }
                        break
                    case 2:
                        if (this.formDataIsSet) {
                            on_invoice_model = "update"
                        } else {
                            on_invoice_model = "create"
                        }
                        data = {
                            invoice_type: 2,
                            invoice_id: this.formData.invoice_id,
                            company_name: this.formData.company_name,
                            company_no: this.formData.company_no,
                            province_id: this.formData.province_id,
                            city_id: this.formData.city_id,
                            district_id: this.formData.district_id,
                            address: this.formData.address,
                            full_address: this.formData.full_address,
                            company_tel: this.formData.company_tel,
                            bank_name: this.formData.bank_name,
                            bank_account: this.formData.bank_account
                        }
                        break
                    case 3:
                        if (this.formDataDialog2.company_name.length == 0) {
                            this.$message.error("发票抬头不能为空")
                            return
                        }
                        if (this.formSwitch_is_default2) {
                            this.formDataDialog2.is_default = 1
                        } else {
                            this.formDataDialog2.is_default = 0
                        }
                        data = {
                            invoice_type: this.on_invoice_type,
                            invoice_id: this.formDataDialog2.invoice_id,
                            company_name: this.formDataDialog2.company_name,
                            is_default: this.formDataDialog2.is_default
                        }
                        break
                }

                if (on_invoice_model == "create") {
                    createInvoice(data)
                        .then(res => {
                            if (res.code == 0) {
                                this.formDataDialogVisible = false
                                this.formDataDialogVisible2 = false
                                this.deleteFormData()
                                // 发票信息列表
                                this.dataInitInvoice()
                                this.formIsEdit = false
                                this.$message.success("保存成功!")
                            } else {
                                this.flag = false
                                this.$message({ message: res.message, type: "warning" })
                            }
                        })
                        .catch(err => {
                            this.flag = false
                            this.$message.error(err.message)
                        })
                } else if (on_invoice_model == "update") {
                    updateInvoice(data)
                        .then(res => {
                            if (res.code == 0) {
                                this.formDataDialogVisible = false
                                this.formDataDialogVisible2 = false
                                this.deleteFormData()
                                // 发票信息列表
                                this.dataInitInvoice()
                                this.formIsEdit = false
                                this.$message.success("编辑成功!")
                            } else {
                                this.flag = false
                                this.$message({ message: res.message, type: "warning" })
                            }
                        })
                        .catch(err => {
                            this.flag = false
                            this.$message.error(err.message)
                        })
                }
            },
            // 清空弹窗表单数据
            deleteFormData() {
                this.formDataDialog.invoice_id = ""
                this.formDataDialog.company_name = ""
                this.formDataDialog.company_no = ""
                this.formDataDialog.is_default = ""
                this.formDataDialog2.invoice_id = ""
                this.formDataDialog2.company_name = ""
                this.formDataDialog2.is_default = ""
                this.formSwitch_is_default = false
                this.formSwitch_is_default2 = false
            },
            handleSizeChange(val) {
                this.pagesize = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            /**
             * 改变省
             */
            changeProvice(id) {
                this.getAreas(id, data => (this.city = data))
                let obj = {}
                obj = this.province.find(item => {
                    //这里的province就是上面遍历的数据源
                    return item.id === id //筛选出匹配数据
                })
                this.formData.city_id = ""
                this.formData.district_id = ""
                this.formData.full_address = obj.name // 设置选中的地址
            },
            /**
             * 改变市
             */
            changeCity(id) {
                this.getAreas(id, data => (this.district = data))
                let obj = {}
                obj = this.city.find(item => {
                    //这里的province就是上面遍历的数据源
                    return item.id === id //筛选出匹配数据
                })
                this.formData.district_id = ""
                this.formData.full_address = this.formData.full_address + "-" + obj.name
            },
            /**
             * 改变区
             */
            changeDistrict(id) {
                let obj = {}
                obj = this.district.find(item => {
                    //这里的province就是上面遍历的数据源
                    return item.id === id //筛选出匹配数据
                })
                this.formData.full_address = this.formData.full_address + "-" + obj.name
            },
            /**
             * 获取地址信息
             */
            getAddressDetail() {
                addressInfo({
                    id: this.formData.id
                })
                    .then(res => {
                        let data = res.data
                        if (data != null) {
                            this.formData.name = data.name
                            this.formData.mobile = data.mobile
                            this.formData.telephone = data.telephone
                            this.formData.address = data.address
                            this.formData.full_address = data.full_address
                            this.formData.latitude = data.latitude
                            this.formData.longitude = data.longitude
                            this.formData.is_default = data.is_default
                            this.formData.province_id = data.province_id
                            this.formData.city_id = data.city_id
                            this.formData.district_id = data.district_id
                            this.defaultRegions = [data.province_id, data.city_id, data.district_id]
                        }
                    })
                    .catch(err => {})
            },
            // 异步获取地区
            getAreas(pid, callback) {
                getArea({
                    pid: pid
                })
                    .then(res => {
                        if (res.code == 0) {
                            var data = []
                            res.data.forEach((item, index) => {
                                data.push(item)
                            })
                            if (callback) callback(data)
                        }
                    })
                    .catch(err => {})
            },

            /**
             * 获取省市区列表
             */
            getDefaultAreas(pid, obj) {
                getArea({
                    pid: pid
                })
                    .then(res => {
                        if (res.code == 0) {
                            var data = []
                            var selected = undefined
                            res.data.forEach((item, index) => {
                                if (obj != undefined) {
                                    if (obj.level == 0 && obj.province_id != undefined) {
                                        selected = obj.province_id
                                    } else if (obj.level == 1 && obj.city_id != undefined) {
                                        selected = obj.city_id
                                    } else if (obj.level == 2 && obj.district_id != undefined) {
                                        selected = obj.district_id
                                    }
                                }

                                if (selected == undefined && index == 0) {
                                    selected = item.id
                                }
                                data.push(item)
                            })

                            this.pickerValueArray[obj.level] = data
                            if (obj.level + 1 < 3) {
                                obj.level++
                                this.getDefaultAreas(selected, obj)
                            } else {
                                this.isInitMultiArray = true
                                this.isLoadDefaultAreas = true
                            }

                            this.province = this.pickerValueArray[0]
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },

            /**
             * 渲染默认值
             */
            handleDefaultRegions() {
                var time = setInterval(() => {
                    if (!this.isLoadDefaultAreas) return
                    this.isInitMultiArray = false

                    for (let i = 0; i < this.defaultRegions.length; i++) {
                        for (let j = 0; j < this.pickerValueArray[i].length; j++) {
                            this.province = this.pickerValueArray[0]

                            // 匹配省
                            if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
                                // 设置选中省
                                this.$set(this.multiIndex, i, j)
                                // 查询市
                                this.getAreas(this.pickerValueArray[i][j].id, data => {
                                    this.city = data

                                    for (let k = 0; k < this.city.length; k++) {
                                        if (this.defaultRegions[1] == this.city[k].id) {
                                            // 设置选中市
                                            this.$set(this.multiIndex, 1, k)

                                            // 查询区县
                                            this.getAreas(this.city[k].id, data => {
                                                this.district = data
                                                // 设置选中区县
                                                for (let u = 0; u < this.district.length; u++) {
                                                    if (this.defaultRegions[2] == this.district[u].id) {
                                                        this.$set(this.multiIndex, 2, u)
                                                        this.handleValueChange({
                                                            detail: {
                                                                value: [j, k, u]
                                                            }
                                                        })
                                                        break
                                                    }
                                                }
                                            })

                                            break
                                        }
                                    }
                                })
                            }
                        }
                    }
                    if (this.isLoadDefaultAreas) clearInterval(time)
                }, 100)
            },
            onSubmit() {
                console.log("submit!")
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
    .card-title {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .box {
        width: 100%;
        position: relative;
    }

    .null-page {
        width: 100%;
        height: 730px;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    .member-info {
        background: #ffffff;
        padding: 20px;
    }

    .invoice {
        &_head {
            margin-bottom: 10px;
        }

        &_table {
            margin-bottom: 10px;
        }

        &_page {
            ::v-deep .el-pagination {
                text-align: center;
            }
        }
    }
</style>
